import React from "react";
import styled from "styled-components";

interface SourceWordProps {
    source: string;
    current: string;
}

export const SourceWord: React.FC<SourceWordProps> = ({
  source,
  current
}) => {
    const highlighted = getHighlight(source, current);
    return <div>
        {highlighted.map((letterState, index) => {
            return <Letter
                key={index}
                isUsed={letterState.isUsed}
            >
                {letterState.letter}
            </Letter>
        })}
    </div>;
}

interface LetterState {
    letter: string;
    isUsed: boolean;
}

function getHighlight(source: string, current: string): LetterState[] {
    const result = [];
    let remainingUsed = current;
    for (const letter of source.toUpperCase()) {
        if (remainingUsed.includes(letter)) {
            remainingUsed = remainingUsed.replace(letter, "");
            result.push({ letter, isUsed: true });
        } else {
            result.push({ letter, isUsed: false });
        }
    }
    return result;
}

const Letter = styled.span<{ isUsed: boolean }>`
font-weight: bold;
    color: ${({ isUsed }) => isUsed ? "lightgray" : "#db2828" };
    font-size: 18px;
    `;