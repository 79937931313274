import { useRef } from "react";
import styled from "styled-components";

interface TextInputProps {
    value: string;
    setValue: (value: string) => void;
    placeholder?: string;
    isSearch?: boolean;
    isError?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({ 
    value,
    setValue,
    placeholder,
    isSearch = false,
    isError = false
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return <InputEl 
        ref={inputRef} 
        value={value}
        type={isSearch ? "search" : "text"}
        placeholder={placeholder}
        onChange={(event) => { setValue(event.target.value) }}
        isError={isError}
    />
}

const InputEl = styled.input<{ isError: boolean}>`
    -webkit-appearance: none;
    height: 29.5px;
    border: 1px solid #bababa;
    border-radius: 6px;
    padding: 0px 10px;
    font-size: 15px;
    width: calc(100% - 20px);
    background-color: white;

    outline: none;

    &:active, &:focus {
        outline: none;
        border: 1px solid #d01919;
    }

    ${({ isError }) => isError ? `
        background-color: #f0d4d4;
        color: #d01919;
    ` :
    ""}
`;