import DICTIONARY from "../assets/dictionary.json"

export function isWordInDictionary(word: string): boolean {
    return word.toLowerCase() in DICTIONARY;
}
export function getScore(wordList: string[]) {
    const points = wordList.reduce((score, word) => {
        return score + word.length;
    }, 0);
    const words = wordList.length;
    return { points, words };
}

interface WordGroup {
    length: number;
    words: string[];
}

export function partitionWords(words: string[]): WordGroup[] {
    const groups: {[length: number]: WordGroup} = {};
    for (const word of words) {
        const group = groups[word.length] || { length: word.length, words: [] };
        groups[word.length] = { ...group, words: [...group.words, word ]};
    }
    return Object.values(groups);
}

export function usesAllowedLetters(source: string, current: string): boolean {
    let remainingAllowed = source.replace(/ /g, "").toUpperCase();
    for (const letter of current) {
        if (remainingAllowed.includes(letter)) {
            remainingAllowed = remainingAllowed.replace(letter, "");
        } else {
            return false;
        }
    }

    return true;
}

export function canAddWord(source: string, word: string, words: string[]): boolean {
    if (words.includes(word)) {
        console.log("Word already used.");
        return false;
    }

    if (word === source) {
        console.log("Word is source.");
        return false;
    }

    if (!isWordInDictionary(word)) {
        console.log("Word is not a word.");
        return false;
    }

    if (word.length < 3) {
        console.log("Too short");
        return false;
    }

    const variants = [
        word.replace(/S$/, ""),
        word.replace(/ES$/, ""),
        word.replace(/D$/, ""),
        word.replace(/ED$/, ""),
        word.replace(/R$/, ""),
        word + "S",
        word + "ES",
        word + "D",
        word + "ED",
        word + "R"
    ]

    if (variants.some((variant) => words.includes(variant))) {
        console.log("Variant is used.");
        return false;
    }

    return true;
}
