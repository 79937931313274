import React, {useEffect, useState} from "react";
import { SourceWord, CurrentWord, CompletedWords, NewGameDialog } from "./components";
import {canAddWord, getScore} from "../../logic/game";
import {GameData, getStoredGame, setStoredGame} from "../../logic/storage";
import styled from "styled-components";
import { Button } from "./components/Button";
import { useSearchParams } from "react-router-dom";

export const Game: React.FC = () => {
    const [params, setParams] = useSearchParams();
    const source = params.get("phrase") || "ANAGRAMZ";
    const savedGame = getStoredGame(source) || { source, words: [] };
    const [current, setCurrent] = useState("");
    const words = savedGame.words;
    const [isNewGameDialogOpen, setNewGameDialogOpen] = useState(false);

    const onLoadGame = (game: GameData) => {
        params.set("phrase", game.source.toUpperCase());
        setParams(params);
        setNewGameDialogOpen(false);
    }

    useEffect(() => {
        if (params.get("phrase") === null) {
            params.set("phrase", "ANAGRAMZ");
            setParams(params);
        }
    }, [params, setParams]);

    const score = getScore(words);

    const onSubmitWord = (): boolean => {
        if (!canAddWord(source, current, words)) {
            return false;
        }
        setStoredGame({ source, words: [...words, current] });
        setCurrent("");
        return true;
    }

    const onStartGame = (source: string) => {
        params.set("phrase", source.toUpperCase());
        setParams(params);
    }

    const onOpenDialog = () => {
        setNewGameDialogOpen(true)
    }

    return <div>
        <OuterDiv>
            <TopBar>
                <ScoreLabel>{score.words} Word{score.words === 1 ? "" : "s"} / {score.points} Points </ScoreLabel>
                <Button onClick={onOpenDialog} disabled={isNewGameDialogOpen}>New Game</Button>
            </TopBar>
            { isNewGameDialogOpen ? <Content>
                <Main>
                    <NewGameDialog
                        isOpen={isNewGameDialogOpen}
                        setOpen={setNewGameDialogOpen}
                        onStartGame={onStartGame}
                        onLoadGame={onLoadGame}
                    />
                </Main>
            </Content> : <Content>
                <SortaTopBar>
                    <SourceWord source={source} current={current} />
                </SortaTopBar>
                <NotReallyTopBar>
                    <CurrentWord value={current} setValue={setCurrent} source={source} submitWord={onSubmitWord} />
                </NotReallyTopBar>
                <Main>
                    <CompletedWords words={words} />
                </Main>
            </Content>}
        </OuterDiv>
    </div>;
}

const OuterDiv = styled.div`
    display: flex;
    flex-direction: column;
    `

const TopBar = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid #eaeaea;
padding: 10px 15px;
margin-bottom: 5px;
align-items: center;
position: fixed;
width: calc(100% - 30px);
backdrop-filter: blur(2px);
background-image: linear-gradient(#ffffff, #ffffffce);
    `;

const SortaTopBar = styled.div`
display: flex;
justify-content: space-around;
padding: 15px;
    `;

const NotReallyTopBar = styled.div`
padding: 5px 15px;
padding-bottom: 10px;
    `;

const Content = styled.div`
padding-top: 65px;
    `;

const Main = styled.div`
padding: 15px;
    `;

const ScoreLabel = styled.div`
font-size: 18px;
padding: 8px 0;
`;