export interface GameData {
    source: string;
    words: string[];
}

const STORAGE_KEY = "SAVED_GAMES";

export function setStoredGame(game: GameData): void {
    const games = getStoredGames();
    const newGamesBySource = new Map(games.map(
        (oldGame) => [oldGame.source.toUpperCase(), oldGame]
    ));
    newGamesBySource.set(game.source.toUpperCase(), game);
    const newGames = Array.from(newGamesBySource.values());
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newGames));
}

export function getStoredGames(): GameData[] {
    let loaded;
    try {
        loaded = JSON.parse(localStorage.getItem(STORAGE_KEY) || "[]");
    } catch(error) {
        console.error(error);
        return [];
    }
    if (!Array.isArray(loaded)) {
        return [];
    }
    return loaded.filter((game) => {
        return game.source !== undefined
        && typeof game.source === "string"
        && Array.isArray(game.words)
        && game.words.every((word: any) => typeof word === "string")
    });
}

export function getStoredGame(source: string): GameData | undefined {
    return getStoredGames().find(
        (game) => source.toUpperCase() === game.source.toUpperCase()
    );
}
