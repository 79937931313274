import React from "react";
import styled from "styled-components";
import { partitionWords } from "../../../../logic/game";

interface CompletedWordsProps {
    words: string[];
}

function alphabetically(a: string, b: string) {
    return a.localeCompare(b);
}

export const CompletedWords: React.FC<CompletedWordsProps> = ({ words }) => {
    const groups = partitionWords(words);
    const lastWord = words.length > 0 ? words[words.length - 1] : undefined;
    return <OuterBox>
        {groups.map((group) => {
            return <GroupBox key={group.length}>
                <GroupLabel>{group.length} Letters</GroupLabel>
                {group.words.sort(alphabetically).map((word, index) => {
                    return <div 
                        key={index}
                        style={{ color: lastWord === word ? "#db2828" : "black" }}
                    >
                        {word}
                    </div>
                })}
            </GroupBox>;
        })}
    </OuterBox>
}

const OuterBox = styled.div`
    column-count: 2;
  column-width: 50%;
  `

const GroupLabel = styled.div`
border-bottom: 1px solid #eaeaea;
width: 100%;
color: #db2828;
    `;

const GroupBox = styled.div`
    width: 100%;
    margin-bottom: 10px;
  word-break: keep-all;
  display: inline-block;
    `;