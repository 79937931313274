import styled from "styled-components";

export const Button = styled.button`
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 15px;
    white-space: nowrap;
    cursor: pointer;
    height: 31.5px;
    min-width: 70px;

    background-color: #d01919;
    color: white;
    border: 1px solid #a32f2f;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &:disabled {
        background-color: #c65b5b;
    }

    &:focus {
        outline: none;
    }

    &[type=submit] {
        font-weight: inherit;
    }
`;