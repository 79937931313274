import React, {useState} from "react";
import styled from "styled-components";
import { getScore } from "../../../../logic/game";
import { GameData, getStoredGames } from "../../../../logic/storage";
import { Button } from "../Button";
import { TextInput } from "../TextInput";

interface NewGameDialogProps {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onStartGame: (source: string) => void;
    onLoadGame: (game: GameData) => void;
}

export const NewGameDialog: React.FC<NewGameDialogProps> = ({ isOpen, setOpen, onStartGame, onLoadGame }) => {
    const [source, setSource] = useState("");

    const onCancel = () => {
        setOpen(false);
        setSource("");
    }

    const onConfirm = () => {
        onStartGame(source);
        setOpen(false);
        setSource("");
    }

    const onChange = (value: string) => {
        setSource(value.toUpperCase());
    }

    const storedGames = getStoredGames();

    return isOpen ? <div>
        <DialogContent>
            <InputLabel>Pick a source phrase:</InputLabel>
            <TextInput value={source} setValue={onChange} />
        </DialogContent>
        <FormRow>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>Start</Button>
        </FormRow>
        { storedGames.length > 0 && <HistoricalGames>
            Or, continue a past game:
            { storedGames.reverse().map((game) => {
                const score = getScore(game.words);
                return <HistoricalGameRow 
                    key={game.source}
                    onClick={() => onLoadGame(game)}
                >
                    <HistoricalGameWord>{game.source.toUpperCase()}</HistoricalGameWord>
                    {score.points} Points / {score.words} Word{score.words === 1 ? "" : "s"}
                </HistoricalGameRow>
            }) }
        </HistoricalGames>}
    </div> : null;
}

const DialogContent = styled.div`
display: flex;
flex-direction: column;
    `;

const InputLabel = styled.div`
margin-bottom: 5px;
    `;

const FormRow = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

const HistoricalGameRow = styled.div`
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 5px;
    border: 1px solid #efefef;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;

    &:hover {
        background-color: #fcfcfc;
    }
`;

const HistoricalGameWord = styled.div`
    font-weight: bold;
    color: #db2828;
    font-size: 18px;
`;

const HistoricalGames = styled.div`
    margin-top: 20px;
`;