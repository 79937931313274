import React, {useState} from "react";
import styled from "styled-components";
import { usesAllowedLetters } from "../../../../logic/game";
import { Button } from "../Button";
import { TextInput } from "../TextInput";

interface CurrentWordProps {
    source: string;
    value: string;
    setValue: (value: string) => void;
    submitWord: () => boolean;
}

export const CurrentWord: React.FC<CurrentWordProps> = ({
                                                           value,
                                                           setValue,
                                                           source,
                                                            submitWord
}) => {
    const [error, setError] = useState(false);

    const trySetValue = (value: string) => {
        const newLetters = value.toUpperCase();
        if (usesAllowedLetters(source, newLetters)) {
            setValue(value.toUpperCase());
            setError(false);
        }
    }

    return <RowForm onSubmit={(event) => {
        event.preventDefault();
        setError(!submitWord());
    }}>
        <TextInput
            value={value}
            setValue={trySetValue}
            isError={error}
        />
        <Button type='submit'>Submit</Button>
    </RowForm>;
}

const RowForm = styled.form`
    display: flex;
    gap: 10px;
    width: 100%;
`;